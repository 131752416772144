<template>
  <div class="rc_container">
    <div class="rc_content_banner">
      <img :src="rcbanner" alt="" />
    </div>
    <!-- <span class="title">选址要求</span>
    <span class="title_en">Site Selection Requirements</span>
    <div class="rc_content_step">
      <div class="rc_content_step_main">
        <hr class="rc_content_step_main_line" />
        <div class="rc_content_step_main_icon">
          <img :src="chengshi" />
        </div>
        <div class="rc_content_step_main_txt">
          <div class="rc_content_step_main_txt_title">城市及地段</div>
          <div class="rc_content_step_main_txt_content">
            <span>一线城市商圈</span>
            <span>二线城市的一、二类商圈</span>
          </div>
        </div>
      </div>
      <div class="rc_content_step_main">
        <hr class="rc_content_step_main_line" />
        <div class="rc_content_step_main_icon">
          <img :src="wuye" />
        </div>
        <div class="rc_content_step_main_txt">
          <div class="rc_content_step_main_txt_title">物业条件</div>
          <div class="rc_content_step_main_txt_content">
            <span>面积30㎡-100㎡</span>
          </div>
        </div>
      </div>
      <div class="rc_content_step_main">
        <div class="rc_content_step_main_icon">
          <img :src="jingying" />
        </div>
        <div class="rc_content_step_main_txt">
          <div class="rc_content_step_main_txt_title">经营模式</div>
          <div class="rc_content_step_main_txt_content">
            <span>商业综合体独立门店</span>
            <span>商业综合体配套服务</span>
          </div>
        </div>
      </div>
    </div>
    <span class="title">购买流程</span>
    <span class="title_en">Purchase Process</span>
    <div class="rc_content_introduce">
      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">申请留言</span>
          <span class="rc_box_content_top_num">01</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 线上扫码</div>
          <div class="rc_box_content_bottom_txt">· 客服电话</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">意向沟通</span>
          <span class="rc_box_content_top_num">02</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 填写资料</div>
          <div class="rc_box_content_bottom_txt">· 资料初筛</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">政策宣讲</span>
          <span class="rc_box_content_top_num">03</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 线上课程</div>
          <div class="rc_box_content_bottom_txt">· 补充资料</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">意向签订</span>
          <span class="rc_box_content_top_num">04</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 资格复审</div>
          <div class="rc_box_content_bottom_txt">· 面试评估</div>
          <div class="rc_box_content_bottom_txt">· 意向合同</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">选址审核</span>
          <span class="rc_box_content_top_num">05</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 选址资料整理</div>
          <div class="rc_box_content_bottom_txt">· 选址审核</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">合同签订 </span>
          <span class="rc_box_content_top_num">06</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 签订合同</div>
          <div class="rc_box_content_bottom_txt">· 缴保证金</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">门店装修</span>
          <span class="rc_box_content_top_num">07</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 方案设计</div>
          <div class="rc_box_content_bottom_txt">· 装修验收</div>
          <div class="rc_box_content_bottom_txt">· 设备安装</div>
          <div class="rc_box_content_bottom_txt">· 证照办理</div>
        </div>
      </div>

      <div class="rc_box_content">
        <div class="rc_box_content_top">
          <span class="rc_box_content_top_title">培训开业</span>
          <span class="rc_box_content_top_num">08</span>
        </div>
        <div class="rc_box_content_bottom">
          <div class="rc_box_content_bottom_txt">· 运营培训</div>
          <div class="rc_box_content_bottom_txt">· 授权开业</div>
        </div>
      </div>
    </div> -->
    <span class="title">业务咨询</span>
    <span class="title_en">Business Consultation</span>
    <div class="rc_content_contact">
      <div class="rc_content_contact_item">
        <div class="rc_content_contact_item_main">15363426273</div>
        <div class="rc_content_contact_item_text">客服热线</div>
      </div>
      <div class="rc_content_contact_item">
        <div class="rc_content_contact_item_main">15363426273@163.com</div>
        <div class="rc_content_contact_item_text">联系邮箱</div>
      </div>
      <div class="rc_content_contact_item">
        <div class="rc_content_contact_item_main">
            <img :src="rcqr" alt="">
        </div>
        <div class="rc_content_contact_item_text">客服微信</div>
      </div>
    </div>
  </div>
</template>

<script>
import {  kfqr, rcbanner, } from "@/mock/data";

export default {
  metaInfo: {
    title: '彬茂建材-关于我们',
    meta: [
      {             // set meta
        name: '彬茂建材,广东彬茂,广东彬茂新型建筑材料有限公司,环保材料,建筑装修,建筑装修,环保建筑装修材料,木饰面,碳晶板,金属线条,装修设计效果图,防火防水装修材料',
        content: '我们主要使用的是竹木纤维材质，竹木纤维采用竹粉、木粉、碳粉，天然树脂 微晶颗粒技术通过高温高压压缩而成的一种新型环保装饰材料。' +
          '厂家这边也在不断的研发新型产品应用于各大领域，2023迎合市场发展需求，第九代产品 冰火板 金刚板' +
          '全铝板材全国首发上线进入市场（太空舱便捷旅馆和睡眠舱，蜗居等新型便捷住宿城市旅馆是我们的研发项目，城市外来人口越来越多，外来务工人员旅游出差人员入住方便，经济实惠，便民利民是我们主推的发展理念）'
      },
    ],
  },
  components: {},
  methods: {},
  data() {
    return {
      rcqr:kfqr,
      rcbanner: rcbanner
    };
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/style/global.scss";
.rc_container {
  width: 100%;
  height: auto;
  @include flex(column, flex-start, center);
  .rc_content_banner {
    width: 100%;
    background-color: #333;
    img {
      width: 100%;
    }
  }
  .title {
    @include gew(36px);
    margin-top: 98px;
    color: #343434;
  }
  .title_en {
    @include pfl(20px);
    margin-top: 24px;
    margin-bottom: 49px;
    color: #777777;
  }
  .rc_content_step {
    width: 1440px;
    flex: 0 0 300px;
    @include flex(row, space-between, center);
    .rc_content_step_main {
      flex: 0 0 300px;
      height: 100%;
      @include flex(row, flex-start, flex-start);
      position: relative;
      .rc_content_step_main_line {
        position: absolute;
        width: 261px;
        height: 3px;
        border: none;
        border-top: 3px dotted #999;
        top: 15px;
        left: 260px;
      }
      .rc_content_step_main_icon {
        flex: 0 0 55px;
        height: 55px;
        img {
          height: 100%;
        }
        margin-right: 13px;
      }
      .rc_content_step_main_txt {
        flex: 1;
        height: 100%;
        @include flex(column, flex-start, flex-start);
        .rc_content_step_main_txt_title {
          flex: 0 0 55px;
          width: 100%;
          @include flex(row, flex-start, center);
          @include eew(18px);
          color: #343434;
        }
        .rc_content_step_main_txt_content {
          flex: 1;
          width: 100%;
          @include flex(column, flex-start, flex-start);
          @include pfl(18px);
          color: #777777;
          margin-bottom: 16px;
        }
      }
    }
  }
  .rc_content_introduce {
    width: 1440px;
    flex: 0 0 300px;
    @include flex(row, space-between, center);
    flex-wrap: wrap;

    .rc_box_content {
      flex: 0 0 343px;
      height: 130px;
      @include flex(column, flex-end, space-between);
      position: relative;
      .rc_box_content_top {
        flex: 0 0 51px;
        width: 100%;
        @include flex(row, space-between, flex-end);
        @include graduateColor(left, #000, #fff);
        opacity: 0.7;
        //   margin-bottom: 14px;
        .rc_box_content_top_title {
          @include eew(18px);
          color: #fff;
          margin-left: 18px;
          margin-bottom: 10px;
          opacity: 1;
        }
        .rc_box_content_top_num {
          @include pfm(22px);
          color: #777;
          margin-right: 18px;
          margin-bottom: 5px;
          opacity: 1;
        }
      }
      .rc_box_content_bottom {
        height: 100%;
        width: 90%;
        margin-left: 17px;
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        .rc_box_content_bottom_txt {
          @include pfl(18px);
          color: #777777;
          margin-right: 25px;
        }
      }
    }
  }
  .rc_content_contact {
    width: 1440px;
    flex: 0 0 465px;
    @include flex(row, space-between, center);
    .rc_content_contact_item {
      flex: 0 0 465px;
      height: 100%;
      
      @include flex(column, center, center);
      .rc_content_contact_item_main {
        flex: 1;
        width: 100%;
        @include flex(column, center, center);
        @include pfm(24px, bold);
        img {
            width: 131px;
            flex: 0 0 131px;
        }
      }
      .rc_content_contact_item_text {
        flex: 0 0 75px;
        width: 100%;
        @include flex(column, flex-start, center);
        @include pfm(18px);
        color: #777777;
      }
    }
  }
}
</style>